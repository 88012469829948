<div class="message">
  <div class="message-title">
    <span>{{data.title}}</span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="message-text">
    {{data.message}}
  </div>

  <div class="message-button">
    <button mat-stroked-button mat-dialog-close color="warn">Закрыть</button>
  </div>
</div>
