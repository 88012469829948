import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SharedMaterial } from '@shared/material/shared-material';

@Component({
  selector: 'app-message-dialog',
  standalone: true,
  imports: [SharedMaterial.MatIconModule, SharedMaterial.MatButtonModule, SharedMaterial.MatDialogModule],
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
    },
    public dialogRef: MatDialogRef<MessageDialogComponent>,) {
  }
}
