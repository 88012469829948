<div class="message">
  <div class="message-title">
    <span>Сообщение</span>
    <button mat-icon-button mat-dialog-close [disabled]="loading">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form" class="message-form">
    <mat-form-field  appearance="outline" [floatLabel]="'always'">
      <mat-label>Ваше имя</mat-label>
      <input #name matInput maxlength="30" formControlName="name">
      <mat-hint align="end">{{name.value ? name.value.length : 0}}/30</mat-hint>
    </mat-form-field>
    <mat-form-field  appearance="outline" [floatLabel]="'always'">
      <mat-label>Номер телефона</mat-label>
      <input matInput maxlength="30" formControlName="phone"  [showMaskTyped]="true" [dropSpecialCharacters]="false" prefix="+375" mask="(00)000-00-00" >
    </mat-form-field>
    <div class="message-message">
      Ожидайте звонка нашего специалиста в течении пары минут
    </div>
    <button mat-flat-button color="warn" [appLoading]="loading" [disabled]="form.invalid  || !(pipelineState$ | async)" (click)="save()">Отправить
    </button>
    <div class="message-description">* Нажимая кнопку отправить вы соглашаетесь на обработку персональных данных в соответсвтии с правилами настоящего сайта.</div>
  </form>
</div>
