import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: '[appLoading]',
  standalone: true,
})
export class LoadingDirective implements OnChanges {
  @Input('appLoading') loading = false;
  isButton = false;
  styles: any;
  attributesStyles: string = '';

  private spinner: ComponentRef<MatProgressSpinner> | null = null;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.['loading']) {
      return;
    }

    if (changes['loading'].currentValue) {
      if (typeof this.elementRef.nativeElement.disabled === 'boolean') {
        this.isButton = true;
        this.elementRef.nativeElement.disabled = true;
      }

      this.setStyles();
      this.createSpinner();
    } else {
      if (!changes['loading'].firstChange) {
        if (this.isButton) {
          this.elementRef.nativeElement.disabled = false;
        }
        this.destroySpinner();
        this.removeStyles();
      }
    }
  }

  private createSpinner(): void {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = 'indeterminate';
      this.renderer.appendChild(this.elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);
    }
  }

  private setStyles(): void {
    const height = this.elementRef.nativeElement.clientHeight;
    const width = this.elementRef.nativeElement.clientWidth;
    this.styles = { ...this.elementRef.nativeElement.style };
    this.attributesStyles = this.elementRef.nativeElement.attributes.style?.value;
    if (height > 0 && width > 0) {
      this.elementRef.nativeElement.style.height = `${height}px`;
      this.elementRef.nativeElement.style.width = `${width}px`;
    } else {
      this.elementRef.nativeElement.style.minHeight = `100%`;
      this.elementRef.nativeElement.style.minWidth = `100%`;
    }

    this.elementRef.nativeElement.style.display = 'flex';
    this.elementRef.nativeElement.style.justifyContent = 'center';
    this.elementRef.nativeElement.style.alignItems = 'center';
    this.elementRef.nativeElement.deletedHTML = this.elementRef.nativeElement.innerHTML;
    this.elementRef.nativeElement.innerHTML = '';

    for (const child of this.elementRef.nativeElement.children) {
      this.elementRef.nativeElement.lastChild.style.oldDisplay = this.elementRef.nativeElement.lastChild.style.display;
      this.elementRef.nativeElement.lastChild.style.display = 'none';
    }
  }

  private removeStyles(): void {
    for (let i = 0; i < this.elementRef.nativeElement.children.length; i++) {
      this.elementRef.nativeElement.children[i].style.display =
        this.elementRef.nativeElement.children[i].style.oldDisplay;
    }
    this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.deletedHTML;
    this.elementRef.nativeElement.style = { ...this.styles };
    this.elementRef.nativeElement.setAttribute('style', this.attributesStyles);
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
