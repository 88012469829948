
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { NgOptimizedImage } from '@angular/common'
import { HttpClientModule } from '@angular/common/http';

export const Shared = {
    CommonModule: CommonModule,
    ReactiveFormsModule: ReactiveFormsModule,
    NgxMaskDirective: NgxMaskDirective,
    NgxMaskPipe: NgxMaskPipe,
    provideNgxMask: provideNgxMask,
    RouterModule: RouterModule,
    NgOptimizedImage: NgOptimizedImage,
    HttpClientModule: HttpClientModule
}

