import { Component } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { SendDataService } from '@services/send-data-service';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SharedMaterial } from '@shared/material/shared-material';
import { Shared } from '@shared/shared';
import { SharedDirective } from '@shared/directive';
import { LoadingDirective } from '@shared/directive/items/loading.directive';

@Component({
  selector: 'app-send-message-dialog',
  standalone: true,
  imports: [
    Shared.CommonModule,
    Shared.ReactiveFormsModule,
    Shared.NgxMaskDirective,
    SharedMaterial.MatFormFieldModule,
    SharedMaterial.MatButtonModule,
    SharedMaterial.MatIconModule,
    SharedMaterial.MatDialogModule,
    SharedMaterial.MatInputModule,
    LoadingDirective,
  ],
  providers: [Shared.provideNgxMask()],
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.scss']
})
export class SendMessageDialogComponent {
  loading = false;
  form = this.fb.nonNullable.group({
    name: ['', Validators.required],
    phone: ['', Validators.required]
  })

  public pipelineState$ = this.sendDataService.pipelineState$;
  constructor(private fb: FormBuilder,
    private sendDataService: SendDataService,
    private dialogService: MatDialog,
    public dialogRef: MatDialogRef<SendMessageDialogComponent>, private snackBar: MatSnackBar) {
  }

  save() {
    const value = this.form.value
    if (!value.phone || !value.name) {
      return
    }
    const valueLength = this.sendDataService.validatePhone(value.phone)
    if (!valueLength) {
      this.sendDataService.showMessage('Введите полный номер', 'Ошибка')
      return
    }
    this.loading = true
    this.sendDataService.sendDataMessage(value.phone, value.name).then((data) => {
      if (!data.errors) {
        this.sendDataService.showMessage('Сообщение успешно отправлено', 'Спасибо')
        this.dialogRef.close();
      } else {
        this.loading = false
        this.sendDataService.showMessage(data.errors?.message?.join(', '), 'Ошибка')
      }
    }).catch(() => {
      this.loading = false
      this.sendDataService.showMessage('Ошибка отправки сообщения', 'Ошибка')
    })
  }
}
