import { Injectable, afterNextRender } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MessageDialogComponent } from "@shared/components/items/message-dialog/message-dialog.component";
import { SendMessageDialogComponent } from "@shared/components/items/send-message-dialog/send-message-dialog.component";
import { BehaviorSubject } from "rxjs";

export interface ApiError {
  message: string[];
  details: ApiErrorDetails;
}
export interface ApiErrorDetails {
  field_errors: Record<string, ApiErrorList[]>;
  non_field_errors: ApiErrorList[];
}
export interface ApiErrorList {
  [key: string]: string[] | Record<string, ApiErrorList[]>;
}

export interface reviewInterface {
  count: number,
  data: reviewItem[]
}

export interface reviewItem {
  rate: string,
  message: string,
  name: string,
  date: { date: string, time: string },
  telephone: string,
}

export interface promiseData<T> {
  errors: null | ApiError,
  count?: number,
  pipelineStatus?: boolean,
  result: T
}


@Injectable({ providedIn: 'root' })
export class SendDataService {
  private interval: any = null;
  private pipeLineCore: any = null
  private _pipelineState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public get pipelineState$() {
    return this._pipelineState.asObservable()
  }
  constructor(private dialogService: MatDialog) {
    afterNextRender(() => {
      this.checkPipeline();
    })
  }
  public showMessage(message: string, action: string) {
    this.dialogService.open(MessageDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '90%',
      data: {
        title: action,
        message: message
      }
    })
  }

  public validatePhone(phone: string): boolean {
    console.log(phone);
    const valueLength = phone.replace(/[^\d;]/g, '').length;
    return valueLength === 12;

  }

  public promiseError(): Promise<promiseData<any>> {
    return new Promise((resolve, reject) => {
      resolve({
        errors: null,
        result: 'Расширение отправки сообщений не подключено',
        pipelineStatus: false
      })
    })
  }

  sendMessage() {
    const dialogRef = this.dialogService.open(SendMessageDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '90%',
    })
  }

  sendReview(message: string, name: string, telephone: string, rate: number) {
    const sendData = {
      message: message,
      name: name,
      telephone: telephone,
      rate: rate
    }
    if (this.pipeLineCore) {
      return this.pipeLineCore.send.reviews(sendData) as Promise<promiseData<string>>
    }
    return this.promiseError();
  }

  getReviews(page: number, limit?: number): Promise<promiseData<reviewInterface>> {
    if (this.pipeLineCore) {
      return this.pipeLineCore.get.reviews({ page: page, limit: limit ?? 10 }) as Promise<promiseData<reviewInterface>>;
    }
    return this.promiseError()
  }



  public sendDataMessage(phone: string, name: string, message?: string): Promise<promiseData<string>> {
    const sendData = {
      type: 'message',
      telephone: phone,
      name: name,
      message: message ? message : 'Форма'
    }
    if (this.pipeLineCore) {
      return this.pipeLineCore.send.message(sendData) as Promise<promiseData<string>>
    }
    return this.promiseError();
  }

  private getPipeline() {
    let pipelineCore = null
    try {
      // @ts-ignore
      pipelineCore = new PIPELINE_FUNCTIONS() ?? null;
    } catch (err) {
    }
    return pipelineCore
  }

  private checkPipeline() {
    this.interval = setInterval(() => {
      const pipelineCore = this.getPipeline()
      if (pipelineCore) {
        try {
          // @ts-ignore
          if (typeof self.CORE == 'object' && self.CORE.hasOwnProperty('analyticsStatus') && self.CORE.analyticsStatus === true) {
            clearInterval(this.interval);
            this.pipeLineCore = pipelineCore;
            this._pipelineState.next(true);
          }
        } catch (err) {

        }

      }
    }, 100)
  }
}
