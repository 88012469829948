import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { TextFieldModule } from '@angular/cdk/text-field';
export const SharedMaterial = {
    TextFieldModule: TextFieldModule,
    MatIconModule: MatIconModule,
    MatInputModule: MatInputModule,
    MatFormFieldModule: MatFormFieldModule,
    MatButtonModule: MatButtonModule,
    MatSelectModule: MatSelectModule,
    MatCheckboxModule: MatCheckboxModule,
    MatProgressSpinnerModule: MatProgressSpinnerModule,
    MatSnackBarModule: MatSnackBarModule,
    MatProgressBarModule: MatProgressBarModule,
    MatExpansionModule: MatExpansionModule,
    MatTooltipModule: MatTooltipModule,
    MatDialogModule: MatDialogModule,
    MatChipsModule: MatChipsModule,
}
export const SharedMaterialAllImports = [
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogModule,
    MatChipsModule,
]